import { Box, Flex, Text } from 'rebass/styled-components';
import { Link } from 'gatsby';
import React from 'react';

export default function Footer() {
  return (
    <Flex
      variant={'wrapper'}
      flexDirection={['column', 'row', 'row']}
      paddingTop={40}
      paddingBottom={20}
    >
      <Box
        marginRight={[0, 40, null]}
        paddingRight={[0, 40, null]}
        sx={{
          borderRight: [0, '1px solid grey', null],
        }}
      >
        <Text color="text">
          2021, Ośrodek Doskonalenia Nauczycieli „Prolegis” Elżbieta Linowska
          <br />
          Al. Jana Pawła II 59/43
          <br /> 15-704 Białystok
          <br />
          tel. (85) 30 70 999, fax (85) 87 30 058
          <br />
          +48 666 025 333
          <br />
        </Text>
      </Box>
      <Box
        sx={{
          borderRight: [0, '1px solid grey', null],
        }}
        marginRight={[0, 40, null]}
        paddingRight={[0, 40, null]}
        marginTop={[20, 0, null]}
      >
        <Text color="text">Obsługę administracyjną Ośrodka pełni ECRK Lex</Text>
      </Box>
      <Box marginTop={[20, 0, 0]}>
        <Text color="text">
          <Link to={'/polityka-prywatnosci'}>Polityka prywatności</Link>
          <br />
          <a target="blank" href="https://ecrklex.pl/regulamin">
            Regulamin serwisu
          </a>
          <br />
          <a href="mailto:biuro@prolegis.com.pl">biuro@prolegis.com.pl</a>
          <br />
        </Text>
      </Box>
    </Flex>
  );
}
