import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Box, Flex } from 'rebass/styled-components';
import Link from 'gatsby-link';

const siteMetadataQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
          subMenu {
            name
            link
          }
        }
      }
    }
  }
`;

const TopNavigation = () => {
  return (
    <Flex
      sx={{
        marginTop: 90,
      }}
    >
      <StaticQuery
        query={siteMetadataQuery}
        render={(data) => {
          if (data) {
            return data.site.siteMetadata.menuLinks.map((element) => {
              const hasSubMenu = element.subMenu && element.subMenu.length > 0;
              return (
                <Box
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: 50,
                    color: 'text',
                    display: 'block',
                    padding: '1rem',
                    position: 'relative',
                    transitionDuration: '0.5s',
                    ':hover': {
                      cursor: 'pointer',
                    },
                    ':hover > ul, :focus-within > ul ': {
                      visibility: 'visible',
                      opacity: '1',
                      display: 'block',
                      boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.2)',
                    },
                  }}
                  key={element.name}
                >
                  <Link
                    sx={{
                      color: 'white',
                    }}
                    to={hasSubMenu ? null : element.link}
                    aria-haspopup={!!hasSubMenu}
                  >
                    {element.name}
                  </Link>
                  {hasSubMenu ? (
                    <Box
                      as="ul"
                      sx={{
                        listStyle: 'none',
                        m: 0,
                        p: 0,
                        backgroundColor: 'white',
                        visibility: 'hidden',
                        opacity: '0',
                        display: 'none',
                        minWidth: '8rem',
                        position: 'absolute',
                        transition: 'all 0.5s ease',
                        marginTop: '1rem',
                        left: '0',
                        ':hover': {
                          visibility: 'visible',
                          opacity: '1',
                          display: 'block',
                          border: '1px solid dark',
                        },
                      }}
                      aria-label="submenu"
                    >
                      {element.subMenu.map((subLink) => (
                        <Link
                          sx={{
                            color: 'white',
                          }}
                          to={subLink.link}
                          key={subLink.name}
                        >
                          <Box
                            sx={{
                              clear: 'both',
                              width: '100%',
                              padding: '1rem',
                              ':hover': {
                                boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.3)',
                                borderRadius: '1px',
                              },
                            }}
                          >
                            {subLink.name}
                          </Box>
                        </Link>
                      ))}
                    </Box>
                  ) : null}
                </Box>
              );
            });
          }
          return null;
        }}
      />
    </Flex>
  );
};

TopNavigation.propTypes = {};

TopNavigation.defaultProps = {};

export default TopNavigation;
