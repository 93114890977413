import { Box, Button, Flex, Image, Text } from 'rebass/styled-components';
import { Link } from 'gatsby';
import React from 'react';
import logo from '../images/prolegis.svg';
import SearchBar from './SearchBar';

export default function Header() {
  return (
    <Flex variant={'wrapper'}>
      <Flex width={'100%'} justifyContent={'space-between'}>
        <Box>
          <Link to="/">
            <Image src={logo} width={530} />
          </Link>
          <Text color="text" marginTop={40}>
            Akredytacja Podlaskiego Kuratora Oświaty
            <br />
            Nr decyzji: NP.5470.9.2024
            <br />
            Wpis do Rejestru Szkół i Placówek Oświatowych (SIO): 276980
            <br />
          </Text>
        </Box>
        <Flex flexWrap="nowrap" mx={5} boxSizing={'borderBox'}>
          <Box display={'inline-block'} alignSelf={'flex-end'} px={20} py={2}>
            <SearchBar />
          </Box>
          <Box
            alignSelf={'flex-end'}
            px={0}
            marginRight={10}
            py={2}
            width={1 / 3}
          >
            <Link to="/kontakt">
              <Button>Kontakt</Button>
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
