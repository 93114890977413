import React, { useRef, useState } from 'react';
import { Box } from 'rebass/styled-components';
import { Input } from '@rebass/forms';
import { navigate } from 'gatsby-link';
import { Search } from '@material-ui/icons';

const SearchBar = () => {
  const [searchString, setSearchString] = useState('');
  const [isActive, setActive] = useState(false);
  const input = useRef(null);

  const toggleClass = () => {
    setActive(!isActive);
    input.current.focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/szukaj/${searchString}`);
  };

  const openedStyle = {
    width: 200,
  };

  const closedStyle = {
    width: 0,
    padding: 0,
    border: 0,
  };

  const inputStyle = isActive ? openedStyle : closedStyle;

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      display={'flex'}
      flexDirection={'row'}
      height={'50px'}
      overflow={'hidden'}
      outline={'none'}
      alignItems="center"
      sx={{
        position: 'relative',
        float: 'right',
      }}
    >
      <Input
        type="text"
        id="searchBar"
        onChange={(event) => {
          setSearchString(event.target.value);
        }}
        ref={input}
        overflow={'hidden'}
        placeholder={'Szukaj..'}
        sx={{
          border: '2px solid #ccc',
          borderRadius: '4px',
          backgroundColor: 'white',
          outline: '0',
          position: 'relative',
          transition: 'all 0.2s',
          ...inputStyle,
        }}
      />
      <Search
        onClick={toggleClass}
        className={'searchIcon'}
        sx={{ fontSize: 35 }}
      />
    </Box>
  );
};

export default SearchBar;
